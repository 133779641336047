import { Typography } from "@mui/material";
import { Column } from "../../common/styled-components/BasePageStyles";
import COLORS from "../../constants/colors";

const LabelWithValue = ({ label, value, alignRight }) => (
    <Column>
        <Typography textAlign={alignRight ? "right" : "left"} variant="body1" color={COLORS.BLACK}>{label}</Typography>
        <Typography textAlign={alignRight ? "right" : "left"} variant="body2" color={COLORS.BLACK_LIGHT}>{value}</Typography>
    </Column>
);

export default LabelWithValue;