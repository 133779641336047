import { Button, TextField, Typography } from "@mui/material";
import { PageContainer } from "../../../common/styled-components/BasePageStyles";
import { useEffect, useState } from "react";
import http from "../../../common/http";
import UserInfo from "../UserInfo/UserInfo";


const GenerateKyc = () => {
  const [aadhaar, setAadhaar] = useState();
  const [accessToken, setAccessToken] = useState();
  const [otpRefId, setOtpRefId] = useState();
  const [otp, setOtp] = useState()
  const [aadhaarData, setAadhaarData] = useState();
  const [phone, setPhone] = useState();

  const handleChange = (e) => {
    if (!isNaN(Number(e.target.value))) {
      setAadhaar(e.target.value);
    }
  };

  const handleChangeOtp = (e) => {
    if (!isNaN(Number(e.target.value))) {
      setOtp(e.target.value);
    }
  };

  useEffect(() => {
    const authenticate = async () => {
      const res = await http.post("/sandbox/authenticate", null, {
        baseURL: "",
        headers: {
          "content-Type": "application/json",
          "x-api-key": "key_live_Rt5TDey3xr7bdb9uIMfLgUN6fYoPznDo",
          "x-api-secret": "secret_live_l95ss3GhtoxDNDrMzoIKXg2tJsWWrQxa",
          "x-api-version": "1.0",
        },
      });

      setAccessToken(res.data.access_token);
    };

    if(!accessToken) {
      authenticate();
    }
  }, [accessToken]);

  const generateOtp = async () => {
    const res = await http.post(
      "/sandbox/kyc/aadhaar/okyc/otp",
      {
        aadhaar_number: aadhaar,
      },
      {
        baseURL: "",
        headers: {
          "content-Type": "application/json",
          "x-api-key": "key_live_Rt5TDey3xr7bdb9uIMfLgUN6fYoPznDo",
          "x-api-version": "1.0",
          Authorization: `${accessToken}`,
        },
      }
    );

    const refId = res?.data?.data?.ref_id;

    if (refId) {
      setOtpRefId(refId);
    }
  };

  const validateOtp = async () => {
    const otpValidateRes = await http.post(
      "/sandbox/kyc/aadhaar/okyc/otp/verify",
      {
        ref_id: otpRefId,
        otp: otp,
      },
      {
        baseURL: "",
        headers: {
          "content-Type": "application/json",
          "x-api-key": "key_live_Rt5TDey3xr7bdb9uIMfLgUN6fYoPznDo",
          "x-api-version": "1.0",
          Authorization: `${accessToken}`,
        },
      }
    );

    setAadhaarData(otpValidateRes?.data?.data);
  }

  const handlePhoneChange = (e) => {
    if (!isNaN(Number(e.target.value))) {
      setPhone(e.target.value);
    }
  }

  return (
    <PageContainer>
      {!aadhaarData && (<>
        <Typography variant="h2">Start Kyc Process</Typography>
      {otpRefId ? (
        <>
         <TextField
            key="otp"
            InputProps={{ maxLength: 6 }}
            value={otp}
            onChange={handleChangeOtp}
            type="text"
            label="Enter Otp"
          />
          <Button variant="contained" onClick={validateOtp}>
            Validate Otp
          </Button>
        </>
      ) : (
        <>
          <TextField
            key="phone"
            InputProps={{ maxLength: 12 }}
            value={phone}
            onChange={handlePhoneChange}
            type="text"
            label="Phone Number"
          />
          <TextField
            key="aadhaar"
            InputProps={{ maxLength: 12 }}
            value={aadhaar}
            onChange={handleChange}
            type="text"
            label="Aadhaar Number"
          />
          <Button variant="contained" disabled={!phone || !aadhaar} onClick={generateOtp}>
            Generate Otp
          </Button>
        </>
      )}
      </>)}

      {aadhaarData && (<UserInfo userData={aadhaarData} aadhaar={aadhaar} phone={phone} />)}
    </PageContainer>
  );
};

export default GenerateKyc;
