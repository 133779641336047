import { Dialog } from '@mui/material';
import styled from 'styled-components';
import { Column } from '../../../common/styled-components/BasePageStyles';
import COLORS from '../../../constants/colors';

export const FullScreenDialog = styled(Dialog)`
    & *[role="dialog"] {
        height: 100vh;
        width: 100vw;
        padding: 0;
    }
`

export const SearchCard = styled(Column)`
    border-bottom: 1px solid ${COLORS.DIVIDER_GREY};
    padding: 20px 0;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
`