import CreateSalesInvoice from "./pages/CreateSalesInvoice/CreateSalesInvoice"
import FindInvoice from "./pages/FindInvoice/FindInvoice"
import ViewInvoice from "./pages/ViewInvoice/ViewInvoice"

const salesRouter = [
    {
        path: "/sales",
        children: [
            {
                path: "/sales/create-invoice",
                element: <CreateSalesInvoice />
            },
            {
                path: "/sales/view-invoice",
                element: <FindInvoice />
            },
            {
                path: "/sales/view-invoice/:id",
                element: <ViewInvoice />
            }
        ]
    }
]

export default salesRouter