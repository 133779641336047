const COLORS = {
    WHITE: '#FFFFFF',
    BORDER: '#00B2DF',
    BLACK: "#000000",
    BLACK_LIGHT: "rgba(0, 0, 0, 0.87)",
    RED: "#FC7A57",
    GREEN: '#99D19C',
    DIVIDER_GREY: "#D5D8DE",
}

export default COLORS;