import GenerateKyc from "./GenerateKyc/GenerateKyc"

const ekycRouter = [
    {
        path: "/ekyc",
        children: [
            {
                path: "/ekyc/generate",
                element: <GenerateKyc />
            },
        ]
    }
]

export default ekycRouter