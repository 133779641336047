import qs from 'qs'

export const getCustomerByPhone = (phone) => {
    return qs.stringify({
        filters: {
            customerPhone: {
                $eq: phone
            }
        }
    })
}