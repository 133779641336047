import { Button, LinearProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { formatBytes } from "../../../helpers/formatBytes";
import { CloudUpload, Delete } from "@mui/icons-material";
import LabelWithValue from "../LabelWithValue";
import { Column, Row } from "../../../common/styled-components/BasePageStyles";
import { HorizontalSeparator } from "../../../common/styled-components/Separators";

const HiddenInput = styled.input`
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  whitespace: nowrap;
  width: 1;
`;

const DeleteIcon = styled(Delete)`
  & > path {
    color: red;
  }
`;

const FILE_SIZE_LIMIT = 5;

const FileUpload = ({ file, onFileSelected, onFileRemoved, progress }) => {
  const [error, setError] = useState("");

  const addFile = (e) => {
    const file = e.target.files[0];
    const totalFileSize = file?.size / 1000 / 1000 ?? 0;
    if (totalFileSize > 0 && totalFileSize <= FILE_SIZE_LIMIT) {
      onFileSelected(file);
    } else if (totalFileSize > FILE_SIZE_LIMIT) {
      setError(`Please upload file smaller than ${FILE_SIZE_LIMIT} MB`);
    }
  };

  return (
    <>
      {!file ? (
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUpload />}
        >
          Upload file
          <HiddenInput type="file" onChange={addFile} accept=".xlsx" />
        </Button>
      ) : (
        <Column>
          <Row>
            <LabelWithValue label={file.name} value={formatBytes(file.size)} />
            {progress === 100 && (
              <Button
                variant="text"
                startIcon={<DeleteIcon />}
                onClick={onFileRemoved}
              />
            )}
          </Row>
          {progress < 100 && <LinearProgress value={progress} />}
          <HorizontalSeparator />
          {error && <Typography variant="caption">{error}</Typography>}
        </Column>
      )}
    </>
  );
};

export default FileUpload;
