import { Alert, Button, Dialog, DialogActions, DialogTitle, TextField } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { StyledDialogContent } from "./PaymentCreationPopup.styles";
import API_URLS from "../../../constants/api-constants";
import http from "../../../common/http";
import { useState } from "react";
import { LoopOutlined } from "@mui/icons-material";
import { getIdFromResponse } from "../../../common/utils";

const PaymentCreationPopup = ({saleId, paymentIds, isOpen, onClose}) => {

    const [loading, setLoading] = useState(false)
    const [apiError, setApiError] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        defaultValues: {
            cashAmount: 0,
            bankAmount: 0,
            discount: 0
        }
      })

    const onSubmit = async (formData) => {
        setLoading(true)
        try {
            const res = await http.post(API_URLS.PAYMENTS, {data: formData})
            const payemntId = getIdFromResponse(res);
            await http.put(`${API_URLS.SALES}/${saleId}`, {data: {payments: [...paymentIds, payemntId]}})
            onClose(true)
        } catch (err) {
            setApiError(true)
        } finally {
            setLoading(false)
        }
    }

    return <Dialog open={isOpen} onClose={onClose}>
    <FormProvider>
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Add Payment Details</DialogTitle>
            <StyledDialogContent>
                {apiError && <Alert severity="error">Failed To add payment. Try again.</Alert>}
                <TextField type="number" label="Cash Payment" {...register(`cashAmount`, {required: true })} />
                <TextField type="number" label="Bank Payment" {...register(`bankAmount`, {required: true })} />
                <TextField type="number" label="Discount" {...register(`discount`, {required: true })} />
            </StyledDialogContent>
            <DialogActions>
            <Button variant="text" onClick={onClose}>Cancel</Button>
            <Button variant="contained" type="submit" disabled={Object.keys(errors).length || loading} startIcon={loading ? <LoopOutlined /> : <></> }>Create</Button>
            </DialogActions>
        </form>
    </FormProvider>
  </Dialog>

}

export default PaymentCreationPopup;