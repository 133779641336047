import { Loop, Publish } from "@mui/icons-material";
import { Alert, Button, Typography } from "@mui/material";
import { useState } from "react";
import http from "../../../common/http";
import { PageContainer } from "../../../common/styled-components/BasePageStyles";
import FileUpload from "../../../components/molecules/FileUpload/FileUpload";

const ProductImport = () => {
  const [file, setFile] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileId, setFileId] = useState();
  const [importStatus, setImportStatus] = useState();
  const [uploadStatus, setUploadStatus] = useState();

  const [loading, setLoading] = useState(false)

  const handleFileUpload = async (f) => {
    setFile(f);
    try {
      // Create a FormData object to send the file as a multipart/form-data
      const formData = new FormData();
      formData.append("files", f);

      // Make a POST request to the Strapi media endpoint
      const response = await http("/upload", {
        method: "POST",
        data: formData,
        onUploadProgress: (progressEvent) => {
          // Calculate and log the upload progress
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });

      // Check if the request was successful
      if (response && response.data) {
        setFileId(response.data[0].id);
        setUploadStatus("SUCCESS");
      }
    } catch {
      setUploadStatus("FAILED");
    }
  };

  const handleDeleteFile = () => {
    setFile(null);
    setUploadProgress(0);
    setFileId(null);
  };

  const handleImport = async () => {
    setLoading(true)
    try {
      await http.post(`/product/import/${fileId}`);
      setImportStatus("SUCCESS");
    } catch (e) {
      setImportStatus("FAILED");
    } finally {
      setLoading(false)
    }
  };

  return (
    <PageContainer>
      <Typography variant="h2">Import Products</Typography>
      {importStatus === "SUCCESS" ? (
        <Alert severity="success">Import Successful!</Alert>
      ) : (
        <>
          <Typography variant="body">
            Please upload the excel file to add products to selected location
          </Typography>
          <FileUpload
            file={file}
            onFileSelected={(file) => handleFileUpload(file)}
            onFileRemoved={handleDeleteFile}
            progress={uploadProgress}
          />
          {fileId && (
            <Button
              variant="contained"
              startIcon={loading ? <Loop /> : <Publish />}
              onClick={handleImport}
              disabled={loading}
              
            >
              Import
            </Button>
          )}
          {importStatus === "FAILED" && (
            <Alert severity="error">Import Failed. Try again</Alert>
          )}
          {uploadStatus === "FAILED" && (
            <Alert severity="error">Import Failed. Try again</Alert>
          )}
        </>
      )}
    </PageContainer>
  );
};

export default ProductImport;
