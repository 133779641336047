import { Box } from "@mui/material";
import styled from "styled-components";
import { pxToRem } from "../utils";

export const PageContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 80vw;
    margin: 0 auto;
    gap: ${pxToRem(24)};
`

export const VerticalForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: ${pxToRem(24)};
`

export const Row = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const Column = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: ${pxToRem(4)};
`