import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import salesRouter from "./modules/sales/router";
import adminRouter from "./modules/admin/router";
import Dashboard from "./modules/dashboard/pages/Dashboard/Dashboard";
import ekycRouter from "./modules/ekyc/router";

const rootRouter = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      children: [
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        ...salesRouter,
        ...adminRouter,
        ...ekycRouter,
      ]
    }
])

export default rootRouter