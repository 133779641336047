import React from "react";
import { Column, Row } from "../../../common/styled-components/BasePageStyles";
import { Typography } from "@mui/material";
import styled from "styled-components";
import dkLogo from "../../../assets/DKLOGO.png";

const UserInfo = ({ userData, aadhaar, phone }) => {
  const { name, address, photo_link, dob, gender, ref_id, care_of } = userData;

  //   const { street, house, landmark, po, dist, subdist, state, pincode } = address;

  const StyledRow = styled(Row)`
    justify-content: flex-start;
    gap: 48px;
    align-items: flex-start;
  `;

  const CenterRow = styled(Row)`
    align-items: center;
    gap: 72px;
    padding-bottom: 32px;
    justify-content: flex-start;
  `;

  const StyledColumn = styled(Column)`
    justify-content: space-between;
    height: 200px;
  `;

  return (
    <Column
      style={{
        border: "1px dashed black",
        padding: "32px",
        borderRadius: "5px",
      }}
    >
      <CenterRow>
        <img alt="logo" width="52" src={dkLogo} />
        <Typography variant="body2" textAlign="center" fontWeight={700}>
          {/* {aadhaar} */}
          Aadhaar e-Kyc Document Generated on {new Date().toLocaleDateString()}{" "}
          {new Date().toLocaleTimeString()}
        </Typography>
      </CenterRow>

      <StyledRow>
        <img
          style={{ borderRadius: "5px" }}
          src={`data:image/jpeg;base64, ${photo_link}`}
          alt="User"
          className="user-photo"
        />
        <StyledColumn>
          <Column>
            <Typography variant="h5" fontWeight={700}>
              {name}
            </Typography>
            <StyledRow>
              <Typography variant="body2" fontWeight={700} minWidth="85px">
                Date of birth
              </Typography>
              <Typography variant="body2" fontWeight={400}>
                {dob}
              </Typography>
            </StyledRow>
            <StyledRow>
              <Typography variant="body2" fontWeight={700} minWidth="85px">
                Gender
              </Typography>
              <Typography variant="body2" fontWeight={400}>
                {gender}
              </Typography>
            </StyledRow>
            <StyledRow>
              <Typography variant="body2" fontWeight={700} minWidth="85px">
                Phone
              </Typography>
              <Typography variant="body2" fontWeight={400}>
                {phone}
              </Typography>
            </StyledRow>
          </Column>
          <Column>
            <Typography variant="body22" fontWeight={500}>
              {care_of} {address}
            </Typography>
          </Column>
        </StyledColumn>
      </StyledRow>
      <Column>
      <Row style={{justifyContent: "flex-start", gap: "8px"}}>
      <Typography variant="body2" fontWeight={700}>
          Reference id: 
        </Typography>          
        <Typography variant="body2" fontWeight={400}>
          {ref_id}
        </Typography>
      </Row>
      </Column>
      <Typography variant="h4" textAlign="center" fontWeight={700} marginTop="32px">
          {aadhaar}
        </Typography>
    </Column>
  );
};

export default UserInfo;
