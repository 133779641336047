import {
  Alert,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  PageContainer,
  Row,
  VerticalForm,
} from "../../../../common/styled-components/BasePageStyles";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { Add, Delete, LoopOutlined, PersonOutlined } from "@mui/icons-material";
import http from "../../../../common/http";
import API_URLS from "../../../../constants/api-constants";
import {
  getDataFromAttributes,
  getDataListFromResponse,
  getIdFromData,
  getIdFromResponse,
} from "../../../../common/utils";
import { useNavigate } from "react-router";
import {
  AddCustomerBtn,
  AddCustomerRow,
  CustomerDetailsContainer,
  CustomerPhone,
} from "./CreateSalesInvoice.styles";
import { useState } from "react";
import { getCustomerByPhone } from "../../../../constants/api-queries";
import LabelWithValue from "../../../../components/molecules/LabelWithValue";
import CustomerCreationPopup from "../../../../components/organisms/CustomerCreationPopup/CustomerCreationPopup";
import { Search } from "@mui/icons-material";
import ProductSearch from "../../../../components/molecules/product-search/ProductSearch";

const ProductForm = ({ index, register, setValue, watch, remove }) => {
  const [showProductSearch, setShowProductSearch] = useState(false);

  return (
    <>
      <Row>
        <Typography>Product {index + 1}</Typography>
        {index > 0 && (
          <IconButton onClick={() => remove(index)}>
            <Delete />
          </IconButton>
        )}
      </Row>
      <TextField
        InputProps={{
          readOnly: true,
          onClick: () => setShowProductSearch(true),
          endAdornment: (
            <IconButton onClick={() => setShowProductSearch(true)}>
              <Search />
            </IconButton>
          ),
        }}
        label={watch(`products.${index}.imei`) ? "" : "IMEI/Serial number"}
        {...register(`products.${index}.imei`, { required: true })}
      />
      <TextField
        InputProps={{ readOnly: true }}
        label={watch(`products.${index}.productName`) ? "" : "Product Name"}
        {...register(`products.${index}.productName`, { required: true })}
      />
      <TextField
        inputProps={{ inputMode: "numeric" }}
        type="number"
        label={watch(`products.${index}.imei`) ? "" : "Unit Price"}
        {...register(`products.${index}.unitPrice`, { required: true })}
      />
      <TextField
        defaultValue={1}
        inputProps={{ inputMode: "numeric" }}
        type="number"
        label="Quantity"
        {...register(`products.${index}.quantity`, { required: true })}
      />
      {showProductSearch && (
        <ProductSearch
          onClose={() => setShowProductSearch(false)}
          onSelect={({ serial, product, serialNumber, productName, price }) => {
            setValue(`products.${index}.serialId`, serial);
            setValue(`products.${index}.productId`, product);
            setValue(`products.${index}.productName`, productName);
            setValue(`products.${index}.imei`, serialNumber);
            setValue(`products.${index}.unitPrice`, price);

            setShowProductSearch(false);
          }}
        />
      )}
    </>
  );
};

const CreateSalesInvoice = () => {
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerData, setCustomerData] = useState();
  const [loading, setLoading] = useState(false);
  const [invoiceCreationInProgress, setInvoiceCreationInProgress] =
    useState(false);
  const [showCustomerCreationPopup, setShowCustomerCreationPopup] =
    useState(false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      products: [
        {
          imei: "",
          productName: "",
          quantity: "1",
          unitPrice: "",
          serialId: "",
          productId: "",
        },
      ],
    },
  });

  const navigate = useNavigate();

  const formHasErrors = Object.keys(errors).length > 0;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });

  const onSubmit = async (formData) => {
    try {
      if (customerData.id) {
        const products = formData.products.map((product) => ({
          product: product.productId,
          serial_mapping: product?.serialId,
          quantity: product.quantity,
          unitPrice: product.unitPrice,
        }));
        setInvoiceCreationInProgress(true);
        const res = await http.post(API_URLS.SALES, {
          data: { customer: customerData.id, notes: formData.notes, products },
        });
        const id = getIdFromResponse(res);
        navigate(`/sales/view-invoice/${id}`);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setInvoiceCreationInProgress(false);
    }
  };

  const handleAddProduct = () => {
    append();
  };

  const handleAddCustomer = async () => {
    try {
      setLoading(true);
      const res = await http.get(
        `${API_URLS.CUSTOMERS}?${getCustomerByPhone(customerPhone)}`
      );
      const customerList = getDataListFromResponse(res);
      if (customerList.length) {
        setCustomerData({
          id: getIdFromData(customerList[0]),
          ...getDataFromAttributes(customerList[0]),
        });
      } else {
        setCustomerData(null);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  const handlePopupClose = (success) => {
    setShowCustomerCreationPopup(false);
    if (success) {
      handleAddCustomer();
    }
  };

  return (
    <PageContainer>
      <Typography variant="h2">Generate Sales Invoice</Typography>
      <AddCustomerRow>
        <CustomerPhone
          inputProps={{ inputMode: "numeric" }}
          onChange={(e) => setCustomerPhone(e.target.value)}
          value={customerPhone}
          type="number"
          label="Customer Phone"
        />
        <AddCustomerBtn
          disabled={!customerPhone || loading}
          onClick={handleAddCustomer}
          variant="outlined"
          startIcon={loading ? <LoopOutlined /> : <PersonOutlined />}
        >
          Add Customer
        </AddCustomerBtn>
      </AddCustomerRow>

      {customerData && (
        <CustomerDetailsContainer>
          <Typography variant="h6">Customer Details</Typography>
          <Row>
            <LabelWithValue label="Name" value={customerData?.customerName} />
            <LabelWithValue
              alignRight
              label="Address"
              value={customerData?.customerAddress}
            />
          </Row>
          <Row>
            <LabelWithValue
              label="Email"
              value={customerData?.customerEmail || "-"}
            />
            <LabelWithValue
              alignRight
              label="Phone"
              value={customerData?.customerPhone}
            />
          </Row>
        </CustomerDetailsContainer>
      )}

      {customerData === null && (
        <Alert severity="error">
          <Row>
            <Typography variant="body2" color>
              Customer Not Found
            </Typography>
            <Button
              onClick={() => setShowCustomerCreationPopup(true)}
              variant="text"
            >
              Create New
            </Button>
          </Row>
        </Alert>
      )}

      <FormProvider>
        <VerticalForm onSubmit={handleSubmit(onSubmit)}>
          {fields.map((item, index) => (
            <ProductForm
              key={item.id}
              index={index}
              register={register}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
              remove={remove}
            />
          ))}
          <Button variant="text" onClick={handleAddProduct} startIcon={<Add />}>
            Add Product
          </Button>
          <TextField multiline label="Notes" {...register("notes")} />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={invoiceCreationInProgress}
            startIcon={
              invoiceCreationInProgress ? (
                <LoopOutlined className="rotate" />
              ) : (
                <></>
              )
            }
          >
            Generate Invoice
          </Button>
          {formHasErrors && (
            <Typography variant="body1">
              Please fill all the required fields
            </Typography>
          )}
        </VerticalForm>
      </FormProvider>
      {showCustomerCreationPopup && (
        <CustomerCreationPopup
          customerPhone={customerPhone}
          isOpen={showCustomerCreationPopup}
          onClose={handlePopupClose}
        />
      )}
    </PageContainer>
  );
};

export default CreateSalesInvoice;
