export const pxToRem = (px) => `${px / 16}rem`;


export const generateString = (length) => {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    
    return result;
}

export const getDataFromResponse = (res) => {
    return res?.data?.data?.attributes;
}

export const getDataListFromResponse = (res) => {
    return res?.data?.data;
}

export const getDataFromAttributes = (data) => {
    return data?.attributes;
}

export const getDataFromNestedCollection = (data) => {
    return data?.data?.attributes;
}

export const getDataListFromNestedCollection = (data) => {
    return data?.data;
}

export const getIdFromResponse = (res) => {
    return res?.data?.data?.id;
}

export const getIdFromData = (data) => {
    return data?.id;
}

export const formatPrice = (price) => {
    let formatting_options = {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 2,
     }
     let formatter = new Intl.NumberFormat( "en-IN", formatting_options );
     return formatter.format(price)
}