import { LoopOutlined, Search } from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import qs from "qs";
import { useEffect, useRef, useState } from "react";
import http from "../../../common/http";
import { Column, Row } from "../../../common/styled-components/BasePageStyles";
import {
  getDataFromAttributes,
  getDataFromNestedCollection,
  getDataListFromResponse,
  getIdFromData,
} from "../../../common/utils";
import LabelWithValue from "../LabelWithValue";
import { FullScreenDialog, SearchCard } from "./ProductSearch.styles";

const SearchResult = ({ name, serial, onSelect }) => {
  return (
    <SearchCard onClick={onSelect}>
      <LabelWithValue label={name} value={serial} />
    </SearchCard>
  );
};

const ProductSearch = ({ onSelect, onClose }) => {
  const [searchType, setSearchType] = useState("name");
  const [searchVal, setSearchVal] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [searching, setSearching] = useState(false);

  const textBoxRef = useRef();

  useEffect(() => {
    setSearchType("serial");
  }, []);

  useEffect(() => {
    if (textBoxRef.current) textBoxRef?.current.focus();
  }, [searchType]);

  const searchBySerial = async () => {
    const query = qs.stringify({
      filters: {
        serialNumber: {
          $contains: searchVal,
        },
      },
      populate: "*",
    });
    try {
      setSearching(true);
      const res = await http.get(`/serial-mappings?${query}`);
      setSearchResults(getDataListFromResponse(res));
    } catch {
      console.log("search failied");
    } finally {
      setSearching(false);
    }
  };

  const searchByName = async () => {
    const query = qs.stringify({
      filters: {
        name: {
          $contains: searchVal,
        },
      },
      populate: "*",
    });

    try {
      setSearching(true);
      const res = await http.get(`/products?${query}`);
      setSearchResults(getDataListFromResponse(res));
    } catch {
      console.log("searching failed");
    } finally {
      setSearching(false);
    }
  };

  const handleSearchTypeChange = (_, n) => {
    setSearchResults([]);
    setSearchType(n);
  };

  return (
    <FullScreenDialog open={true}>
      <Row>
        <ToggleButtonGroup
          fullWidth
          color="primary"
          value={searchType}
          exclusive
          onChange={handleSearchTypeChange}
        >
          <ToggleButton fullWidth value="serial">
            Search By IMEI/Serial
          </ToggleButton>
          <ToggleButton fullWidth value="name">
            Search By Name
          </ToggleButton>
        </ToggleButtonGroup>
      </Row>
      <DialogContent>
        <Column>
          <TextField
            inputRef={textBoxRef}
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
            inputProps={{
              inputMode: searchType === "serial" ? "numeric" : "text",
            }}
            InputProps={{
              endAdornment: (
                <IconButton
                  disabled={searching}
                  onClick={
                    searchType === "serial" ? searchBySerial : searchByName
                  }
                >
                  {searching ? <LoopOutlined className="rotate" /> : <Search />}
                </IconButton>
              ),
            }}
            label={searchType === "serial" ? "IMEI/Serial" : "Name"}
          />
          {searchResults.map((result) => {
            const row = getDataFromAttributes(result);
            const rowId = getIdFromData(result);
            const product =
              searchType === "serial"
                ? getDataFromNestedCollection(row.product)
                : row;
            const productId =
              searchType === "serial" ? getIdFromData(row.product.data) : rowId;
            const serialNumber =
              row?.serialNumber ||
              (row?.serial_mappings.data.length
                ? row?.serial_mappings.data[0]?.attributes?.serialNumber
                : "-");
            return (
              <SearchResult
                key={rowId}
                onSelect={() =>
                  onSelect({
                    serial:
                      searchType === "serial"
                        ? rowId
                        : serialNumber !== "-"
                        ? row?.serial_mappings.data[0]?.id
                        : undefined,
                    product: productId,
                    serialNumber: serialNumber,
                    productName: product.name,
                    price: product.dealerPrice,
                  })
                }
                name={product.name}
                serial={`${serialNumber} 🏷️ ${product.dealerPrice}`}
              />
            );
          })}
        </Column>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </FullScreenDialog>
  );
};

export default ProductSearch;
