import {
  ArrowBackIos,
  EmailOutlined,
  LocationOnOutlined,
  PaymentOutlined,
  PhoneOutlined,
  PrintOutlined,
  WhatsApp,
} from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import moment from "moment/moment";
import qs from "qs";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import http from "../../../../common/http";
import {
  Column,
  PageContainer,
  Row,
} from "../../../../common/styled-components/BasePageStyles";
import { HorizontalSeparator } from "../../../../common/styled-components/Separators";
import {
  formatPrice,
  getDataFromAttributes,
  getDataFromNestedCollection,
  getDataFromResponse,
  getDataListFromNestedCollection,
} from "../../../../common/utils";
import LabelWithValue from "../../../../components/molecules/LabelWithValue";
import PaymentCreationPopup from "../../../../components/organisms/PaymentCreationPopup/PaymentCreationPopup";
import API_URLS from "../../../../constants/api-constants";
import COLORS from "../../../../constants/colors";
import { InfoRow, ItemCard, PillBg } from "./ViewInvoice.styles";

const ProductTable = ({ products }) => {
  return (
    <Box>
      {products.map((row, i) => (
        <ItemCard>
          <LabelWithValue
            label={`${i + 1}. ${getDataFromNestedCollection(row.product).name}`}
            value={
              getDataFromNestedCollection(row.serial_mapping)?.serialNumber ??
              "-"
            }
          />
          <Row>
            <Typography variant="caption">Quantity: {row.quantity}</Typography>
            <Typography variant="caption">
              Unit Price: {formatPrice(row.unitPrice)}
            </Typography>
          </Row>
        </ItemCard>
      ))}
    </Box>
  );
};

const InfoBox = ({
  label,
  partyName,
  addrLine1,
  addrLine2,
  email,
  phone,
  alignRight,
}) => {
  return (
    <Column>
      <Typography
        textAlign={alignRight ? "right" : "left"}
        variant="h6"
        color="primary"
      >
        {label}
      </Typography>
      <Typography textAlign={alignRight ? "right" : "left"} variant="subtitle2">
        {partyName}
      </Typography>
      <Typography textAlign={alignRight ? "right" : "left"} variant="subtitle2">
        {addrLine1}
      </Typography>
      <Typography textAlign={alignRight ? "right" : "left"} variant="subtitle2">
        {addrLine2}
      </Typography>
      <Typography textAlign={alignRight ? "right" : "left"} variant="subtitle2">
        {email}
      </Typography>
      <Typography textAlign={alignRight ? "right" : "left"} variant="subtitle2">
        {phone}
      </Typography>
    </Column>
  );
};

const ViewInvoice = () => {
  const [invoiceData, setInvoiceData] = useState();
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const grandTotal = useMemo(() => {
    return invoiceData?.products?.reduce((acc, curr) => {
      return acc + curr.unitPrice * curr.quantity;
    }, 0);
  }, [invoiceData]);

  const totalUnits = useMemo(() => {
    return invoiceData?.products?.reduce((acc, curr) => {
      return acc + curr.quantity;
    }, 0);
  }, [invoiceData?.products]);

  const customerData = getDataFromNestedCollection(invoiceData?.customer);
  const payments = getDataListFromNestedCollection(invoiceData?.payments);

  const balanceAmount = useMemo(() => {
    const invoiceTotal = invoiceData?.products?.reduce((acc, curr) => {
      return acc + curr.unitPrice * curr.quantity;
    }, 0);
    const totalPaid = payments?.reduce((acc, curr) => {
      return (
        acc +
        getDataFromAttributes(curr)?.cashAmount +
        getDataFromAttributes(curr)?.bankAmount +
        getDataFromAttributes(curr)?.discount
      );
    }, 0);

    return invoiceTotal - totalPaid;
  }, [invoiceData?.products, payments]);

  const fetchData = async () => {
    const query = qs.stringify({
      populate: {
        customer: { populate: "*" },
        payments: { populate: "*" },
        products: {
          populate: "*",
        },
      },
    });

    try {
      const res = await http.get(`${API_URLS.SALES}/${id}?${query}`);
      setInvoiceData(getDataFromResponse(res));
    } catch (e) {
      console.log("e", e);
    }
  };

  const handlePaymentPopupClose = (success) => {
    setShowPaymentPopup(false);
    if (success) {
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <Column className="no-print">
        <Row>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIos color="primary" />
          </IconButton>
          <PillBg bgcolor={balanceAmount > 0 ? COLORS.RED : COLORS.GREEN}>
            <Typography color={COLORS.WHITE} variant="subtitle">
              Invoice Balance: {formatPrice(balanceAmount)}
            </Typography>
          </PillBg>
        </Row>
        <Row>
          <Button
            variant="text"
            onClick={() => setShowPaymentPopup(true)}
            startIcon={<PaymentOutlined />}
          >
            Add Payment
          </Button>
          <Button
            variant="text"
            onClick={() => window.print()}
            startIcon={<PrintOutlined />}
          >
            Print
          </Button>
          <Button
            variant="text"
            onClick={() =>
              window.open(`https://wa.me/+91${customerData?.customerPhone}`)
            }
            startIcon={<WhatsApp />}
          >
            Open Chat
          </Button>
        </Row>
      </Column>
      <Column>
        <Row>
          <LabelWithValue
            label="Invoice date"
            value={moment(invoiceData?.createdAt).format("DD MMM YYYY")}
          />
          <LabelWithValue label="Invoice number" value={id} alignRight />
        </Row>
        <HorizontalSeparator style={{ margin: "12px 0" }} />
        <Column>
          <Typography variant="h4" color="primary">
            Digi Kashmir
          </Typography>
          <Column>
            <InfoRow>
              <LocationOnOutlined />
              <Typography variant="subtitle">
                Amira Kadal, Lal Chowk, Srinagar
              </Typography>
            </InfoRow>
            <InfoRow>
              <LocationOnOutlined />
              <Typography variant="subtitle">K.P Road, Anantnag</Typography>
            </InfoRow>
          </Column>
          <Column>
            <InfoRow>
              <PhoneOutlined />
              <Typography color={COLORS.BLACK_LIGHT} variant="subtitle">
                7006917274
              </Typography>
            </InfoRow>
            <InfoRow>
              <EmailOutlined />
              <Typography color={COLORS.BLACK_LIGHT} variant="subtitle">
                digikashmirstore@gmail.com
              </Typography>
            </InfoRow>
          </Column>
        </Column>
      </Column>
      <HorizontalSeparator />
      <Row>
        {/* <InfoBox label="From" partyName="Digi Kashmir" addrLine1="" addrLine2="" email="digikashmirstore@gmail.com" phone="+91 7006917274" /> */}
        <InfoBox
          label="Bill To"
          partyName={customerData?.customerName}
          addrLine1={customerData?.customerAddress}
          addrLine2=""
          email={customerData?.customerEmail}
          phone={customerData?.customerPhone}
        />
      </Row>
      <HorizontalSeparator />
      <ProductTable products={invoiceData?.products || []} />
      <Row>
        <LabelWithValue label="Total Units" value={totalUnits} />
        <LabelWithValue
          label="Grand Total"
          value={formatPrice(grandTotal)}
          alignRight
        />
      </Row>
      <HorizontalSeparator />
      <Row>
        <Typography width="100%" variant="body" textAlign="left">
          <b>Notes:</b> {invoiceData?.notes}
        </Typography>
      </Row>
      <Row>
        <Typography width="100%" variant="subtitle2" textAlign="center">
          This is a computer generated invoice. No signature required.
        </Typography>
      </Row>
      {showPaymentPopup && (
        <PaymentCreationPopup
          isOpen={showPaymentPopup}
          paymentIds={payments.map((p) => p.id)}
          saleId={id}
          onClose={handlePaymentPopupClose}
        />
      )}
    </PageContainer>
  );
};

export default ViewInvoice;
