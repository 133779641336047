import { Alert, Button, Dialog, DialogActions, DialogTitle, TextField } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { StyledDialogContent } from "./CustomerCreationPopup.styles";
import API_URLS from "../../../constants/api-constants";
import http from "../../../common/http";
import { useState } from "react";
import { LoopOutlined } from "@mui/icons-material";

const CustomerCreationPopup = ({customerPhone, isOpen, onClose}) => {

    const [loading, setLoading] = useState(false)
    const [apiError, setApiError] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm()

      console.log(errors)

    const onSubmit = async (formData) => {
        setLoading(true)
        try {
            await http.post(API_URLS.CUSTOMERS, {data: {...formData, customerEmail: formData.customerEmail || undefined, customerPhone}})
            onClose(true)
        } catch (err) {
            setApiError(true)
        } finally {
            setLoading(false)
        }
    }

    return <Dialog open={isOpen} onClose={onClose}>
    <FormProvider>
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Create New Customer</DialogTitle>
            <StyledDialogContent>
                {apiError && <Alert severity="error">Failed To create customer. Try again.</Alert>}
                <TextField label="Customer Name" {...register(`customerName`, {required: true })} />
                <TextField multiline label="Customer Address" {...register(`customerAddress`, {required: true })} />
                <TextField label="Customer Email" {...register(`customerEmail`, {required: false })} />
            </StyledDialogContent>
            <DialogActions>
            <Button variant="text" onClick={onClose}>Cancel</Button>
            <Button variant="contained" type="submit" disabled={loading} startIcon={loading ? <LoopOutlined /> : <></> }>Create</Button>
            </DialogActions>
        </form>
    </FormProvider>
  </Dialog>

}

export default CustomerCreationPopup;