import styled from 'styled-components'
import { Row } from '../../../../common/styled-components/BasePageStyles'
import { Button, TextField } from '@mui/material'
import { pxToRem } from '../../../../common/utils'
import { Box } from '@mui/system'
import COLORS from '../../../../constants/colors'

export const AddCustomerRow = styled(Row)`
    gap: ${pxToRem(20)};
    flex-wrap: wrap;
`   
export const CustomerPhone = styled(TextField)`
    flex: 1 0 80vw;
`

export const AddCustomerBtn = styled(Button)`
    width: ${pxToRem(250)};
    height: ${pxToRem(55)};
`

export const CustomerDetailsContainer = styled(Box)`
    border: 1px solid ${COLORS.BORDER};
    padding: ${pxToRem(20)};
    border-radius: ${pxToRem(8)};
    display: flex;
    flex-direction: column;
    gap: ${pxToRem(20)};
`