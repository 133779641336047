import ProductImport from "./product-import/ProductImport"

const adminRouter = [
    {
        path: "/admin",
        children: [
            {
                path: "/admin/product-import",
                element: <ProductImport />
            },
        ]
    }
]

export default adminRouter