import { Box } from "@mui/material";
import { pxToRem } from "../../../../common/utils";
import styled from "styled-components";
import { Column, Row } from "../../../../common/styled-components/BasePageStyles";
import COLORS from "../../../../constants/colors";

export const PillBg = styled(Box)`
    padding: ${pxToRem(12)};
    border-radius: ${pxToRem(8)};
`

export const InfoRow = styled(Row)`
    justify-content: normal;
    gap: ${pxToRem(12)};
`
export const ItemCard = styled(Column)`
    padding: 12px 0;
    border-bottom: 1px solid ${COLORS.DIVIDER_GREY};
`