import { Link } from "react-router-dom";
import {
  Column,
  PageContainer,
} from "../../../../common/styled-components/BasePageStyles";

const Dashboard = () => {
  return (
    <PageContainer>
      <Column>
        <h2>Sales Team</h2>
        <Link to={"/sales/create-invoice"}>Create Sales invoice</Link>
        <Link to={"/sales/view-invoice"}>View/Print Sales Invoice</Link>
      </Column>
      <Column>
        <h2>Admin</h2>
        <Link to={"/admin/product-import"}>Import Products</Link>
      </Column>
    </PageContainer>
  );
};

export default Dashboard;
