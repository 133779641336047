import {  Link, Outlet } from "react-router-dom";
import './global.css';
import Header from "./components/molecules/Header/Header";

function App() {
  return (
   <>
    <Header />
    <Outlet />
   </>
  );
}

export default App;
