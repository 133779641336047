import styled from "styled-components";
import COLORS from "../../constants/colors";

export const VerticalSeparator = styled.div`
  align-self: stretch;
  width: 2px;
  border-bottom: 1px soild ${COLORS.DIVIDER_GREY};
`;
export const HorizontalSeparator = styled.div`
  width: 100%;
  height: 2px;
  border-bottom: 1px solid ${COLORS.DIVIDER_GREY};
`;
