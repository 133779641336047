import { Button, TextField, Typography } from "@mui/material";
import { PageContainer } from "../../../../common/styled-components/BasePageStyles";
import { useState } from "react";
import { useNavigate } from "react-router";

const FindInvoice = () => {

    const [invoiceNumber, setInvoiceNumber] = useState();
    const navigate = useNavigate()

    const handleNavigate = () => {
        if(invoiceNumber) {
            navigate(`${invoiceNumber}`)
        }
    }

    return <PageContainer>
        <Typography variant="h2">Find Invoice</Typography>
        <TextField value={invoiceNumber} onChange={(e) => setInvoiceNumber(e.target.value)} label="Invoice Number" variant="outlined" />
        <Button variant="contained" color="primary" onClick={handleNavigate}>View</Button>
    </PageContainer>
}

export default FindInvoice;