import { Home } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { HeaderRow } from "./Header.styles";

const Header = () => {
  return (
    <HeaderRow className="no-print">
      <Link to="/dashboard">
        <Home />
      </Link>
    </HeaderRow>
  );
};

export default Header;
